<template>
  <b-card>
    <div>
      <h2 class="font-weight-bolder light-blue">
        {{ $t("check_hash") }}
      </h2>
      <b-row class="col-md-12 pr-0 mr-0">
        <div class="col-4 pl-0">
          <b-form-input
              id="hash-code"
              placeholder="hash"
              v-model.trim="hashCode"
              required
          ></b-form-input>
        </div>
        <div class="col-4 pl-0">
          <b-button variant="primary"
          @click="checkHash"
          >{{ $t('check_hash') }}</b-button>
        </div>
      </b-row>
    </div>

    <div v-if="show && invoice" >
    <div class="col-md-12 mt-4">
      <b-row>
        <h2 class="font-weight-bolder light-blue">
        Hash ID :  {{ invoice.id_code}}
        </h2>
      </b-row>
      <b-row>
        <b-col cols="4">
          <h5 class="font-weight-bold">Company Name</h5>
          <p>  {{ invoice.company.name }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <h5 class="font-weight-bold">Invoice Number</h5>
        <p> {{ invoice.invoice_number }} </p>
        </b-col>
        <b-col cols="4">
         <h5 class="font-weight-bold">Invoice Amount</h5>
          <p v-if="invoice.amount">{{ invoice.amount | formatAmount }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
        <h5 class="font-weight-bold">Created at</h5>
        <p v-if="invoice.issue_date">{{ invoice.issue_date | formatDate}}</p>
        </b-col>
        <b-col cols="4" v-if="invoice.due_date">
          <h5 class="font-weight-bold">Due Date</h5>
          {{ invoice.due_date | formatDate}}
        </b-col>
      </b-row>
    </div>
    <div class="col-md-12 mt-1 pl-0 mr-1 pt-2 pb-2">
      <div align="start" class="font-weight-bolder text--gray">
        {{ $t("fractions_bought") }}
      </div>
      <b-table
          striped
          id="fraction-table"
          :items="invoice.fractions_bought"
          :fields="fractionFields"
          responsive
      >
        <template #head()="scope">
          <div class="text-nowrap">{{ $t(scope.label) }}</div>
        </template>
        <template #cell(fraction)="data">

          <span v-if="data.item.fraction"> {{ data.item.fraction }}% </span>
          <span v-else>-</span>
        </template>
        <template #cell(amount)="data">

              <span v-if="data.item.amount">
                {{ data.item.amount | formatAmount }}
              </span>
          <span v-else>-</span>
        </template>
        <template #cell(discount)="data">
              <span v-if="data.item.discount">
                {{ data.item.discount | formatAmount }}
              </span>
          <span v-else>-</span>
        </template>
        <template #cell(issue_date)="data">
              <span v-if="data.item.created_at">
                {{ data.item.created_at | formatDate}}</span>
          <span v-else>-</span>
        </template>
      </b-table>
    </div>
    </div>
  </b-card>
</template>

<script>
// import Table from "@/components/common/Table.vue";
export default {
  name: 'CheckHash',
  data() {
    // components: {
    //   Table
    // }
    return {
      show: false,
      hashCode: null,
      invoice:null,

      fractionFields: [
        {
          label: "date",
          align: "start",
          key: "issue_date",
          variant: "success",
          thClass: "bg-light-success text-dark",
        },
        {
          label: "invoice_id",
          align: "start",
          key: "invoice_id",
          variant: "success",
          thClass: "bg-light-success text-dark",
        },
        {
          label: "amount",
          align: "start",
          variant: "success",
          key: "amount",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
        {
          label: "fraction",
          align: "start",
          key: "fraction",
          variant: "success",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },

        {
          label: "discount",
          align: "start",
          variant: "success",
          key: "discount",
          sortable: false,
          thClass: "bg-light-success text-dark",
        },
      ],

    }
  },
  methods: {
    checkHash() {
      console.log(this.hashCode)
      let data = {
        hashCode: this.hashCode,
      };
    this.$http.post('/market/check/hash', data).then((res) => {
      if(res) {
       this.invoice = res.data.value
        let user = this.$store.state.auth.user.crole;
        console.log(user)
        this.show=true;
      }
    })
    },
  },
}


</script>

<style scoped>

</style>
